export const state = {
    currentUser: sessionStorage.getItem('authUser'),
    token: sessionStorage.getItem('token'),
    refresh_token: sessionStorage.getItem('refresh_token')
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    },
    SET_JWT_TOKEN(state, token, refresh_token) {
        state.token = token;
        state.refresh_token = refresh_token
        save('token', token);
        save('refresh_token', refresh_token);
    },
    GET_REFRESH_TOKEN() {
        window.sessionStorage.getItem('refresh_token');
    }
}

export const getters = {
    loggedIn(state) {
        return !!state.currentUser
    }
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
function save(key, state) {
    window.sessionStorage.setItem(key, state)
}

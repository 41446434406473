import store from "@/state/store";

export default [
  {
    path: "/",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/reset-password",
    name: "Reset password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/admin",
    name: "default",
    meta: {
      title: "Users",
      authRequired: true,
    },
    component: () => import("../views/Admin/Users/index.vue"),
  },
  {
    path: "/admin/users/create",
    name: "Create User",
    meta: {
      title: "Create User",
      authRequired: true,
    },
    component: () => import("../views/Admin/Users/form.vue"),
  },
  {
    path: "/admin/users/:id",
    name: "User update",
    meta: {
      title: "User update",
      authRequired: true,
    },
    component: () => import("../views/Admin/Users/form.vue"),
  },
  {
    path: "/admin/profile",
    name: "Profile",
    meta: {
      title: "Profile",
      authRequired: true,
    },
    component: () => import("../views/account/profile.vue"),
  },
  {
    path: "/admin/permissions",
    name: "Permissions",
    meta: {
      title: "Permissions",
      authRequired: true,
    },
    component: () => import("../views/permissions/form/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        sessionStorage.removeItem('token')
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: "/admin/updates",
    name: "admin_updates",
    meta: {
      title: "Updates",
      authRequired: true,

    },
    component: () => import("../views/Admin/Updates/index.vue"),
  },
  {
    path: "/admin/updates/:id/edit",
    name: "admin_updates_edit",
    meta: {
      title: "Updates edit",
      authRequired: true,
    },
    component: () => import("../views/Admin/Updates/form.vue"),
  },
  {
    path: "/admin/updates/create",
    name: "admin_updates_create",
    meta: {
      title: "Updates create",
      authRequired: true,
    },
    component: () => import("../views/Admin/Updates/form.vue"),
  },
  {
    path: "/admin/ip-addresses",
    name: "admin_ip_addresses",
    meta: {
      title: "Ip Addresses",
      authRequired: true,

    },
    component: () => import("../views/Admin/IpAddresses/index.vue"),
  },
  {
    path: "/admin/ip-addresses/:id/edit",
    name: "admin_ip_addresses_edit",
    meta: {
      title: "Updates edit",
      authRequired: true,
    },
    component: () => import("../views/Admin/IpAddresses/form.vue"),
  },
  {
    path: "/admin/ip-addresses/create",
    name: "admin_ip_addresses_create",
    meta: {
      title: "Updates create",
      authRequired: true,
    },
    component: () => import("../views/Admin/IpAddresses/form.vue"),
  },
  {
    path: "/admin/channel-modes",
    name: "admin_ip_addresses_create",
    meta: {
      title: "Updates create",
      authRequired: true,
    },
    component: () => import("../views/Admin/ChannelModes/index.vue"),
  },
  {
    path: "/admin/channel-mode/:id",
    name: "admin_ip_addresses_edit",
    meta: {
      title: "Updates create",
      authRequired: true,
    },
    component: () => import("../views/Admin/ChannelModes/form.vue"),
  },
  {
    path: "/admin/special-days",
    name: "admin_special_days_list",
    meta: {
      title: "Special day create",
      authRequired: true,
    },
    component: () => import("../views/Admin/SpecialDays/index.vue"),
  },
  {
    path: "/admin/special-days/create",
    name: "admin_special_days_create",
    meta: {
      title: "Special day create",
      authRequired: true,
    },
    component: () => import("../views/Admin/SpecialDays/form.vue"),
  },
  {
    path: "/admin/special-days/:id/edit",
    name: "admin_special_days_edit",
    meta: {
      title: "Updates special day",
      authRequired: true,
    },
    component: () => import("../views/Admin/SpecialDays/form.vue"),
  },
  {
    path: "/admin/channel-ad-time",
    name: "admin_channel_ad_time_list",
    meta: {
      title: "Channel ad time create",
      authRequired: true,
    },
    component: () => import("../views/Admin/ChannelAdTime/index.vue"),
  },
  {
    path: "/admin/channel-ad-time/create",
    name: "admin_channel_ad_time_create",
    meta: {
      title: "Channel add time create",
      authRequired: true,
    },
    component: () => import("../views/Admin/ChannelAdTime/form.vue"),
  },
  {
    path: "/admin/channel-ad-time/:id/edit",
    name: "admin_channel_ad_time_edit",
    meta: {
      title: "Update channel ad time",
      authRequired: true,
    },
    component: () => import("../views/Admin/ChannelAdTime/form.vue"),
  },
  {  path: "/admin/block-colors",
    name: "admin_block_colors_list",
    meta: {
      title: "Channel block color",
      authRequired: true,
    },
    component: () => import("../views/Admin/BlockColors/index.vue"),
  },
  {
    path: "/admin/block-colors/create",
    name: "admin_block_colors_create",
    meta: {
      title: "Channel block color",
      authRequired: true,
    },
    component: () => import("../views/Admin/BlockColors/form.vue"),
  },
  {
    path: "/admin/block-colors/:id/edit",
    name: "admin_block_colors_edit",
    meta: {
      title: "Update block color",
      authRequired: true,
    },
    component: () => import("../views/Admin/BlockColors/form.vue"),
  },
  {
    path: "/admin/logs",
    name: "admin_logs_list",
    meta: {
      title: "Log list",
      authRequired: true,
    },
    component: () => import("../views/Admin/Log/index.vue"),
  },
  {
    path: "/admin/users",
    name: "admin_users_list",
    meta: {
      title: "Users list",
      authRequired: true,
    },
    component: () => import("../views/Admin/Users/index.vue"),
  },
  {
    path: "/admin/users/create",
    name: "admin_users_create",
    meta: {
      title: "Users form",
      authRequired: true,
    },
    component: () => import("../views/Admin/Users/form.vue"),
  },
  {
    path: "/admin/users/edit/:id",
    name: "admin_users_edit",
    meta: {
      title: "Users form",
      authRequired: true,
    },
    component: () => import("../views/Admin/Users/form.vue"),
  },
];

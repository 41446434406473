import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";


import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(ToastPlugin)
    .use(BootstrapVueNext)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(i18n)
    .use(vClickOutside);

app.config.globalProperties.$hasRole = (roleVal) => {
    if (sessionStorage.getItem('auth.currentUser')) {
        const permissions = JSON.parse(sessionStorage.getItem('auth.currentUser')).access;
        return permissions?.some(permission => roleVal.includes(permission.value));
    }
};

app.mount('#app');

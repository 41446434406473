import { createWebHistory, createRouter } from "vue-router";
import axios from 'axios';
import routes from './routes';
import {mutations, state} from "@/state/modules/auth";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, left: 0 };
    }
  },
});
axios.interceptors.request.use(function (config) {
  if (config.method === 'post' && !config.url.includes('configuration/import')) {
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
// API based Authentication
// Before each route evaluates...
// function hasRole(roleValue) {
//   if (sessionStorage.getItem('auth.currentUser')) {
//     const permissions = JSON.parse(sessionStorage.getItem('auth.currentUser')).access;
//     return permissions?.some(permission => permission.value === roleValue);
//   }
//
//   return false;
// }

router.beforeEach(async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
 // const roleName = routeTo.matched[0].meta.role

  if (!authRequired) {
    next();
    return;
  }
  axios.defaults.headers.common['authorization'] = 'Bearer ' + sessionStorage.getItem('token'); // for all requests
  await axios.get(`${process.env.VUE_APP_API_URL}/me`).then((data) => {
    mutations.SET_CURRENT_USER(state, data.data)

    // if (roleName !== undefined && !hasRole(roleName)) {
    //   next('/');
    // }
    next();
  }).catch(() => {
    if (!window.sessionStorage.getItem('refresh_token')) {
      sessionStorage.clear();
      next({name: 'login', query: {redirectFrom: routeTo.fullPath}});
    }
    next('/');
  });
});
axios.interceptors.response.use(
    response => response, // Jeśli nie ma błędu, po prostu zwróć odpowiedź
    async error => {
      const originalRequest = error.config;

      // Sprawdź, czy błąd wynika z przeterminowanego JWT
      if (error.response && error.response.status === 401 && error.response.data.message === 'Expired JWT Token' && !originalRequest._retry) {

        originalRequest._retry = true;

        try {
          axios.defaults.headers.common['authorization'] = null;
          const refreshResponse = await axios.post(`${process.env.VUE_APP_API_URL}/refresh_token`, {
            refresh_token: window.sessionStorage.getItem('refresh_token')
          });

          // Zaktualizuj tokeny w store i w nagłówkach
          mutations.SET_JWT_TOKEN(state, refreshResponse.data.jwt_token, refreshResponse.data.refresh_token)
          axios.defaults.headers.common['authorization'] = `Bearer ${refreshResponse.data.jwt_token}`;
          originalRequest.headers['Authorization'] = `Bearer ${refreshResponse.data.jwt_token}`;
          return axios(originalRequest);
        } catch (refreshError) {
          // W przypadku błędu przy odświeżaniu tokena, czyść sesję i przekieruj na stronę logowania
          sessionStorage.clear();
       //   next({name: 'login', query: {redirectFrom: routeTo.fullPath}});
          return Promise.reject(refreshError);
        }
      }

      // Przekaż błąd dalej, jeśli nie dotyczy on przeterminowanego JWT
      return Promise.reject(error);
    }
);

export default router;
